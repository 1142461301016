import React from "react";

const Controls = ({ numberOfQuestions, currentQuestionNumber, isSubmitted, handleClick }:
    { numberOfQuestions: number, currentQuestionNumber: number, isSubmitted: boolean, handleClick: any }) => {
    // Set prev, next, submit, retake buttons when is submitted and show retake button
    if (isSubmitted) {
        return (
            <div>
                <button type="button" className="btn btn-primary" onClick={() => handleClick('retake')}>Retake</button>
            </div>
        )
    }
    // Set prev, next, submit, retake buttons when currentQuestionNumber is 0
    if (currentQuestionNumber === 0) {
        if (numberOfQuestions === 1) {
            return (
                <div>
                    <button type="button" className="btn btn-primary" onClick={() => handleClick('submit')}>Submit</button>
                </div>
            )
        }
        return (
            <div>
                <button type="button" className="btn btn-primary" onClick={() => handleClick('next')}>Next</button>
            </div>
        )

    }
    // Set prev, next, submit, retake buttons when currentQuestionNumber is greater than 0 and less than numberOfQuestions
    if (currentQuestionNumber > 0 && currentQuestionNumber < numberOfQuestions - 1) {
        return (
            <div>
                <button type="button" className="btn btn-primary" onClick={() => handleClick('prev')}>Previous</button>
                <button type="button" className="btn btn-primary" onClick={() => handleClick('next')}>Next</button>
            </div>
        )
        // Set prev, next, submit, retake buttons when currentQuestionNumber is equal to numberOfQuestions ans show submit button
    } else if (currentQuestionNumber === numberOfQuestions - 1) {
        return (
            <div>
                <button type="button" className="btn btn-primary" onClick={() => handleClick('prev')}>Previous</button>
                <button type="button" className="btn btn-primary" onClick={() => handleClick('submit')}>Submit</button>
            </div>
        )
    }
    else {
        return (
            <div></div>
        )
    }
}

export default Controls;