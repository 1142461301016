import React from 'react';
import ReactFlow from 'reactflow';

import 'reactflow/dist/style.css';

const initialNodes = [
    { id: '1', position: { x: 0, y: 0 }, data: { label: '1' } },
    { id: '2', position: { x: 0, y: 100 }, data: { label: '2' } },
];
const initialEdges = [{ id: 'e1-2', source: '1', target: '2' }];

export type PathNode = {
    id: number,
    title: string,
    category: string,
    summary: string,
    updatedAt: string,
    quizIds: number[],
    articlesIds: number[],
    tags: string[],
}

export type PathProps = {
    paths: PathNode[]
}

const Path = (props: PathProps) => {
    return (
        <div>
            <h1>{props.paths[0].title} Path</h1>
            
            <div style={{ width: '100vw', height: '100vh' }}>
                <ReactFlow nodes={initialNodes} edges={initialEdges} />
            </div>
        </div>
    );
}

export default Path;