export const SEARCH_CONNECTION_FAILING_MSG = "Search connection failed. Please try again later.";
export const SEARCH_NO_RESULTS_MSG = "No results found.";
export const SEARCH_LOADING_MSG = "Searching...";
export const SEARCH_RESULTS_TITLE = "Search results";

export const POSTS_CONNECTION_FAILING_MSG = "Posts connection failed. Please try again later.";
export const POSTS_LOADING_MSG = "Loading posts...";
export const POSTS_NO_RESULTS_MSG = "No posts found.";

export const QUIZZES_CONNECTION_FAILING_MSG = "Quizzes connection failed. Please try again later.";
export const QUIZZES_LOADING_MSG = "Loading quizzes...";
export const QUIZZES_NO_RESULTS_MSG = "No quizzes found.";
