import Post, { PostProps } from './components/post/Post';
import { QuizType } from './components/quiz/Quiz';
import { SEARCH_URL, QUIZZES_URL, QUESTIONS_URL,
         POST_URLS, BASE_URL } from './config';
import Mosaic from './components/mosaic/Mosaic';
import ListResults from './components/searchBar/ListResults';
import QuizIcon from '@mui/icons-material/Quiz';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import CustomCarousel from './components/carousel/Carousel';
import { POSTS_CONNECTION_FAILING_MSG, POSTS_NO_RESULTS_MSG,
         QUIZZES_CONNECTION_FAILING_MSG, QUIZZES_NO_RESULTS_MSG,
         SEARCH_CONNECTION_FAILING_MSG, SEARCH_NO_RESULTS_MSG } from './components/config/loaderConfig';
import axios from 'axios';

const APIKEY = process.env.REACT_APP_APIKEY;

const AxiosInstance = axios.create({
    baseURL: BASE_URL,  
    headers : {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Api-Key': APIKEY
    }
});

const getQuizzes = async () => {

    const quizzes = await AxiosInstance.get(QUIZZES_URL)
        .then(response => response.data)
        .catch(error => {
            console.log('Getting quizzes error:',error);
            return [];
        } );

    return quizzes;
}

export const getQuizzesCards = async (): Promise<any> => {
    const quizzes = await getQuizzes();
    // const quizzes = await getQuizzes();
    if(Object.keys(quizzes).length === 0) {
        return <h2>{QUIZZES_CONNECTION_FAILING_MSG}</h2>;
    }
    const cards = quizzes.map((quiz: any) => {
        return {
            targetId: quiz.id,
            title: quiz.title,
            category: quiz.category.name,
            summary: "",
            updatedAt: new Date(quiz.created_at.slice(0, 10)).toDateString(),
            author: quiz.author,
            buttonText: "Go To Quiz"
        }
    });
    if(cards.length === 0) {
        return <h2>{QUIZZES_NO_RESULTS_MSG}</h2>;
    }
    return <Mosaic title='Quizzes' cards={cards} baseUrl='/quizzes' />;
}

export const getPosts = async () => {
    const posts = await AxiosInstance.get(POST_URLS)
        .then(response => response.data)
        .catch(error => {
            console.log('Getting posts error:', error);
            return [];
        } );

    return posts;
}

export const getPostsCards = async (): Promise<any> => {
    const posts = await getPosts();
    if(Object.keys(posts).length === 0) {
        return <><CustomCarousel/>
        <h2>{POSTS_CONNECTION_FAILING_MSG}</h2></>;
    }

    const cards = posts.map((item: any) => {
        return {
            targetId: item.id,
            title: item.title,
            category: item.category?.name,
            summary: item.summary,
            updatedAt: new Date(item.created_at.slice(0, 10)).toDateString(),
            author: item.author,
            buttonText: "Go To Post",
        }
    });

    if (posts.length === 0) {
        return <><CustomCarousel/>
                <br />
                <h4>{POSTS_NO_RESULTS_MSG}</h4>
               </>;
    }
    else {
        return <>
                        <CustomCarousel/>
                        <Mosaic 
                        cards={cards}
                        baseUrl='/posts'
                        />
        </>
    }
}

// export const getPost = async ({ params }: any): Promise<PostProps> => {
export const getPost = async ({ params }: any): Promise<any> => {
    const url = `${POST_URLS}/${params.postId}`;
    const post = await AxiosInstance.get(url)
    .then(response => response.data)
    .catch(error => {
        console.log('Getting posts error:', error);
        return [];
    } );
    const result = {
        id: post.id,
        title: post.title,
        author: post.authors.join(', '),
        published: post.published,
        category: post.category?.name,
        content: post.content,
        createdAt: new Date(post.created_at.slice(0, 10)).toDateString(),
        quiz: post.quiz,
        tags: post.tags
    };

    return <Post {...result} />;
}

export const getQuiz = async ({ params }: any): Promise<QuizType> => {
    const url = `${QUIZZES_URL}/${params.quizId}`;
    const quiz = await AxiosInstance.get(url)
    .then(response => response.data)
    .catch(error => {
        console.log('Getting quizzes error:', error);
        return [];
    } );

    return {
        id: quiz.id,
        title: quiz.title,
        tags: quiz.tags,
        category: quiz.category.name,
        author: quiz.author,
        createdAt: new Date(quiz.created_at.slice(0, 10)).toDateString(),
        questions: quiz.questions,
        published: quiz.published,
    }

}

export const getQuestion = async ({ params }: any): Promise<any> => {
    const url = `${QUESTIONS_URL}/${params.questionId}`;
    const question = await AxiosInstance.get(url)
    .then(response => response.data)
    .catch(error => {
        console.log('Getting question error:', error);
        return [];
    } );

    return question;
}

export const getQuizWithAdapter = async (params: any) => {
    return {quiz: await getQuiz(params)};
}

export const getSearchResultCards = async ({params}: any): Promise<any>  => {
    const url = `${SEARCH_URL}${params.search_text}`;
    const response = await AxiosInstance.get(url)
    .then(response => response.data)
    .catch(error => {
        console.log('Getting results error:', error);
        return [];
    } );

    if(Object.keys(response).length === 0) {
        return <h2>{SEARCH_CONNECTION_FAILING_MSG}</h2>;
    }

    if(response.Article?.length === 0 && response.Quiz?.length === 0) {
        return <h2>{SEARCH_NO_RESULTS_MSG}</h2>;
    }
    
    const postCards =  response.Article.map((post: any) => {
        return {
            targetId: post.id,
            title: post.title,
            category: post.category?.name,
            summary: post.summary,
            updatedAt: new Date(post.created_at.slice(0, 10)).toDateString(),
            // author: post.authors.join(', '),
            author: post.author,
            buttonBaseUrl: '/posts',
            buttonText: "Go To Post",
            listIcon: <NewspaperIcon />
        }
    });
    
    const quizCards = response.Quiz.map((quiz: any) => {
        return {
            targetId: quiz.id,
            title: quiz.title,
            category: quiz.category.name,
            summary: "",
            updatedAt: new Date(quiz.created_at.slice(0, 10)).toDateString(),
            author: quiz.author,
            buttonText: "Go To Quiz",
            buttonBaseUrl: '/quizzes',
            listIcon: <QuizIcon />
        }
    }
    );

    return <>
    <ListResults cards={postCards.concat(quizCards)} title='Search results'/>
    </>;

}

export const getSearchResultError = async () => {
    return <h2>{SEARCH_NO_RESULTS_MSG}</h2>;
}
