import React from "react";
import { OutlinedCardProps } from "../mosaic/Card";
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import CommentIcon from '@mui/icons-material/Comment';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from "react-router-dom";
var slugify = require('slugify');


type ListResultsProps = {
    title?: string;
    cards: OutlinedCardProps[]
}

const ListResults = (props: ListResultsProps) => {
    const navigate = useNavigate();
    const [selectedIndex, setSelectedIndex] = React.useState(1);

    const handleListItemClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
        url: string
    ) => {
        setSelectedIndex(index);
        navigate(url);
    };

    return (

        <div>
            <h2>{props.title}</h2>
            <List sx={{ width: '100%', maxWidth: 650, minWidth: 100, bgcolor: 'background.paper' }}>
                {props.cards.map((card, index) => (
                    <ListItemButton
                        // selected={selectedIndex === index}
                        onClick={(event) => handleListItemClick(event, index, card.buttonBaseUrl + "/" + card.targetId.toString() + "/" + slugify(card.title, { lower: true }) )}
                    >
                        <ListItemIcon>
                            {card.listIcon  ? card.listIcon : <CommentIcon />}
                        </ListItemIcon>
                        <ListItemText primary={card.title + " | " + card.summary.split(' ').slice(0,10).join(' ')}/>
                    </ListItemButton>
                ))}
            </List>

        </div>
    )
}


export default ListResults;

function setSelectedIndex(index: number) {
    throw new Error("Function not implemented.");
}
