import React from "react";
import TextField from '@mui/material/TextField';
import { useParams } from "react-router-dom";
import {searchUrl} from '../../config';
import { useNavigate } from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from "@mui/material";

const SearchBar = () => {
    const navigate = useNavigate();
    const  searchTextFieldRef= React.useRef<HTMLInputElement>(null);
    const [searchText, setSearchText] = React.useState<string>('');

    const handleEnter = (event:any) => {
        event.preventDefault();
        if (event.key === 'Enter') {
            navigate(`/search/${event.target.value}`);
        }   
    }
    const handleSearchClick = (event:any) => {
        event.preventDefault();
        navigate(`/search/${searchText}`);
    }
    return (
        <div style={{margin:'0px'}}>
            <TextField id="outlined-basic"
                       label="Search..."
                       size="small"
                       onChange={(event) => setSearchText(event.target.value)}
                       InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon  
                                onMouseOver={(target) => { target.currentTarget.style.cursor = "pointer"; } }
                                onMouseOut={(target) => { target.currentTarget.style.cursor = "default"; } }
                                onClick={handleSearchClick}
                                />
                          </InputAdornment>
                        ),
                      }}
                      sx={{marginTop:'10px', marginBottom:'10px'}}
                       variant="outlined" 
                       style={{width:"220px"}}  
                       onKeyUp={handleEnter}/>
        </div>
    )
}


export default SearchBar;