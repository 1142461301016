
import { QuestionTypeConsts } from './components/quiz/question/Consts';
import Quiz from './components/quiz/Quiz';
import { OutlinedCardProps } from './components/mosaic/Card';
import sampleQuiz from './sampleQuiz.json';

export const quizSample = {
  id: 123,
  title: "Some Quiz",
  tags: ["tag1", "tag2"],

  questions: [
    {
      id: 123,
      question: "What is the answer to life, the universe, and everything?",
      choices: [
        {
          text: "42"
        },
        {
          text: "24"
        },
        {
          text: "I don't know"
        }
      ],
      correct: "I don't know",
      answer: "42",
      type: QuestionTypeConsts.MULTIPLE_CHOICE
    },
    {
      id: 456,
      question: "What is the best programming language?",
      choices: [
        {
          text: "Java"
        },
        {
          text: "JavaScript"
        },
        {
          text: "TypeScript"
        },
        {
          text: "Python"
        }
      ],
      correct: "TypeScript",
      answer: "TypeScript",
      type: QuestionTypeConsts.MULTIPLE_CHOICE
    }
  ]
}
export const postsSample = [
  {
    id: 1,
    title: "Design Patterns",
    author: "Admin",
    published: "2023-09-11",
    category: "Programming", //programming, design, architecture, testing, devops, management
    content: <>
      <Quiz quiz={sampleQuiz} />
      <p>Functions are essential for programming.</p>
    </>
  },
  {
    id: 2,
    title: "Some Article2",
    author: "Some Author2",
    published: "2021-10-01",
    category: "Some Category2",
    content: <>
      <Quiz quiz={quizSample} />
      <p>Functions are essential for programming.</p>
    </>
  }, {
    id: 3,
    title: "Some Article3",
    author: "Some Author3",
    published: "2021-10-01",
    category: "Some Category3",
    content: <>
      <Quiz quiz={quizSample} />
      <p>Functions are essential for programming.</p>
    </>
  },
  {
    id: 4,
    title: "Some Article4",
    author: "Some Author4",
    published: "2021-10-01",
    category: "Some Category4",
    content: <>
      <Quiz quiz={quizSample} />
      <p>Functions are essential for programming.</p>
    </>
  },
  {
    id: 5,
    title: "Some Article5",
    author: "Some Author5",
    published: "2021-10-01",
    category: "Some Category5",
    content: <>
      <Quiz quiz={quizSample} />
      <p>Functions are essential for programming.</p>
    </>
  },
  {
    id: 6,
    title: "Some Article6",
    author: "Some Author6",
    published: "2021-10-01",
    category: "Some Category6",
    content: <>
      <Quiz quiz={quizSample} />
      <p>Functions are essential for programming.</p>
    </>
  }, {
    id: 7,
    title: "Some Article7",
    author: "Some Author7",
    published: "2021-10-01",
    category: "Some Category7",
    content: <>
      <Quiz quiz={quizSample} />
      <p>Functions are essential for programming.</p>
    </>
  }, {
    id: 8,
    title: "Some Article8",
    author: "Some Author8",
    published: "2021-10-01",
    category: "Some Category8",
    content: <>
      <Quiz quiz={quizSample} />
      <p>Functions are essential for programming.</p>
    </>
  }, {
    id: 9,
    title: "Some Article9",
    author: "Some Author9",
    published: "2021-10-01",
    category: "Some Category9",
    content: <>
      <Quiz quiz={quizSample} />
      <p>Functions are essential for programming.</p>
    </>
  }, {
    id: 10,
    title: "Some Article10",
    author: "Some Author10",
    published: "2021-10-01",
    category: "Some Category10",
    content: <>
      <Quiz quiz={quizSample} />
      <p>Functions are essential for programming.</p>
    </>
  }
]

export const mosaicSample = {
  cards: [
    {
      targetId: 1,
      title: "Design Patterns",
      category: "Programming", 
      summary: "Some Summary",
      updatedAt: "2023-09-11",
      author: "Admin",
      buttonText: "Go to Quiz"
    },
    {
      targetId: 2,
      title: "Some Title2",
      category: "Some Category2",
      summary: "Some Summary2",
      updatedAt: "2021-10-02",
      author: "Some Author2",
      buttonText: "Go to Quiz"
    },
    {
      targetId: 3,
      title: "Some Title3",
      category: "Some Category3",
      summary: "Some Summary3",
      updatedAt: "2021-10-03",
      author: "Some Author3",
      buttonText: "Go to Quiz"
    },
    {
      targetId: 4,
      title: "Some Title4",
      category: "Some Category4",
      summary: "Some Summary4",
      updatedAt: "2021-10-04",
      author: "Some Author4",
      buttonText: "Go to Quiz"
    },
    {
      targetId: 5,
      title: "Some Title5",
      category: "Some Category5",
      summary: "Some Summary5",
      updatedAt: "2021-10-05",
      author: "Some Author5",
      buttonText: "Go to Quiz"
    },
    {
      targetId: 6,
      title: "Some Title6",
      category: "Some Category6",
      summary: "Some Summary6",
      updatedAt: "2021-10-06",
      author: "Some Author6",
      buttonText: "Go to Quiz"
    },
    {
      targetId: 7,
      title: "Some Title7",
      category: "Some Category7",
      summary: "Some Summary7",
      updatedAt: "2021-10-07",
      author: "Some Author7",
      buttonText: "Go to Quiz"
    },
    {
      targetId: 8,
      title: "Some Title8",
      category: "Some Category8",
      summary: "Some Summary8",
      updatedAt: "2021-10-08",
      author: "Some Author8",
      buttonText: "Go to Quiz"
    },
    {
      targetId: 9,
      title: "Some Title9",
      category: "Some Category9",
      summary: "Some Summary9",
      updatedAt: "2021-10-09",
      author: "Some Author9",
      buttonText: "Go to Quiz"
    },
    {
      targetId: 10,
      title: "Some Title10",
      category: "Some Category10",
      summary: "Some Summary10",
      updatedAt: "2021-10-10",
      author: "Some Author10",
      buttonText: "Go to Quiz"
    },
    {
      targetId: 11,
      title: "Some Title11",
      category: "Some Category11",
      summary: "Some Summary11",
      updatedAt: "2021-10-11",
      author: "Some Author11",
      buttonText: "Go to Quiz"
    },
  ]
}

export const pathsCardsSample = {
  cards: [
    {
      targetId: 1,
      title: "Python Semi Senior Web Developer",
      category: "Some Category",
      summary: "Some Summary",
      updatedAt: "2021-10-01",
      author: "Some Author",
      buttonText: "Go to Path",
    },
    {
      targetId: 2,
      title: "Python Junior Web Developer",
      category: "Some Category",
      summary: "Some Summary",
      updatedAt: "2021-10-01",
      author: "Some Author",
      buttonText: "Go to Path",
    },
  ]
}

export const pathsSample = [
  {
    id: 1,
    title: "Pytho Semi Senior Web developer",
    category: "Some Category",
    summary: "Some Summary",
    updatedAt: "2021-10-01",
    quizIds: [1, 2, 3, 4, 5],
    articlesIds: [1, 2, 3, 4, 5],
    tags: ["tag1", "tag2"],
  },
]

export const quizSample2 = {
  "questions": [
      {
          "question": "Which design pattern provides an interface for creating objects, but lets subclasses decide which class to instantiate?",
          "options": [
              "Abstract Factory Pattern",
              "Builder Pattern",
              "Prototype Pattern",
              "Singleton Pattern"
          ],
          "answer": "Abstract Factory Pattern"
      },
      {
          "question": "Which design pattern allows us to create a separate class to hold the responsibility of creating objects?",
          "options": [
              "Abstract Factory Pattern",
              "Builder Pattern",
              "Prototype Pattern",
              "Singleton Pattern"
          ],
          "answer": "Builder Pattern"
      },
      {
          "question": "Which design pattern represents an object that has the same functionality as another object and can be used as a substitute for that object?",
          "options": [
              "Abstract Factory Pattern",
              "Adapter Pattern",
              "Composite Pattern",
              "Decorator Pattern"
          ],
          "answer": "Decorator Pattern"
      },
      {
          "question": "Which design pattern allows only one instance of a class to be created and provides a global point of access to that instance?",
          "options": [
              "Abstract Factory Pattern",
              "Builder Pattern",
              "Prototype Pattern",
              "Singleton Pattern"
          ],
          "answer": "Singleton Pattern"
      },
      {
          "question": "What is the purpose of the Adapter design pattern?",
          "options": [
              "To convert the interface of a class into another interface clients expect",
              "To control the communication between objects in a system",
              "To provide a simple interface for a complex system",
              "To enhance the performance of a system"
          ],
          "answer": "To convert the interface of a class into another interface clients expect",
          "explanation": "The Adapter design pattern allows objects with incompatible interfaces to work together. It converts the interface of a class into another interface that clients expect."
      },
      {
          "question": "Which design pattern is used to provide a surrogate or placeholder for another object?",
          "options": [
              "Bridge",
              "Proxy",
              "Decorator",
              "Facade"
          ],
          "answer": "Proxy",
          "explanation": "The Proxy design pattern provides a surrogate or placeholder for another object to control access to it."
      },
      {
          "question": "In the Composite design pattern, what is a leaf node?",
          "options": [
              "A node that has child nodes",
              "A node that doesn't have any child nodes",
              "A node that represents the root of the composite structure",
              "A node that holds references to other nodes"
          ],
          "answer": "A node that doesn't have any child nodes",
          "explanation": "In the Composite design pattern, a leaf node is a node that doesn't have any child nodes. It represents an individual object in the composite structure."
      },
      {
          "question": "What is the purpose of the Flyweight design pattern?",
          "options": [
              "To manage the creation and use of objects",
              "To provide a simple interface for a complex system",
              "To enhance the performance of a system",
              "To convert the interface of a class into another interface clients expect"
          ],
          "answer": "To manage the creation and use of objects",
          "explanation": "The Flyweight design pattern is used to manage the creation and use of objects in order to minimize memory usage and improve performance."
      },
      {
          "question": "Which design pattern allows objects to communicate with each other without knowing their concrete classes?",
          "options": [
              "Composite",
              "Observer",
              "Iterator",
              "Mediator"
          ],
          "answer": "Mediator",
          "explanation": "The Mediator design pattern allows objects to communicate with each other without knowing their concrete classes. It promotes loose coupling and simplifies the interaction between objects."
      },
      {
          "question": "What is the purpose of the Decorator pattern?",
          "options": [
              "To extend the functionality of an object dynamically",
              "To encapsulate the creation of a complex object",
              "To provide a way to access the elements of an aggregate object sequentially",
              "To separate the construction of a complex object from its representation"
          ],
          "answer": "To extend the functionality of an object dynamically",
          "explanation": "The Decorator pattern allows behavior to be added to an object dynamically at runtime, without affecting the behavior of other objects in the same class. It provides a flexible alternative to subclassing for extending functionality."
      },
      {
          "question": "Which pattern is used to create an interface that can be accessed using a different interface?",
          "options": [
              "Adapter",
              "Proxy",
              "Flyweight",
              "Facade"
          ],
          "answer": "Adapter",
          "explanation": "The Adapter pattern allows the conversion of the interface of a class into another interface that clients expect. It helps in adapting the incompatible interfaces to work together."
      },
      {
          "question": "What problem does the Composite pattern solve?",
          "options": [
              "How to represent a tree structure of objects",
              "How to provide a simplified interface to a complex subsystem",
              "How to add behavior to individual objects dynamically",
              "How to decouple an abstraction from its implementation"
          ],
          "answer": "How to represent a tree structure of objects",
          "explanation": "The Composite pattern is used to represent a hierarchical structure of objects as a tree. It allows clients to treat individual objects and compositions of objects uniformly."
      },
      {
          "question": "Which pattern is used to hide the complexity of a subsystem and provide a simplified interface?",
          "options": [
              "Decorator",
              "Observer",
              "Proxy",
              "Facade"
          ],
          "answer": "Facade",
          "explanation": "The Facade pattern provides a simplified interface to a complex system of classes, hiding the complexity and providing a higher-level interface for clients to use."
      },
      {
          "question": "Which pattern is used to share objects that can be used in multiple contexts efficiently?",
          "options": [
              "Flyweight",
              "Iterator",
              "Template Method",
              "Singleton"
          ],
          "answer": "Flyweight",
          "explanation": "The Flyweight pattern is used to minimize memory usage by sharing objects that can be used in multiple contexts. It allows for the creation of a large number of fine-grained objects efficiently."
      },
      {
          "question": "What problem does the Bridge pattern solve?",
          "options": [
              "How to represent a tree structure of objects",
              "How to provide a simplified interface to a complex subsystem",
              "How to add behavior to individual objects dynamically",
              "How to decouple an abstraction from its implementation"
          ],
          "answer": "How to decouple an abstraction from its implementation",
          "explanation": "The Bridge pattern decouples an abstraction from its implementation, allowing them to vary independently. It provides a way to handle new or anticipated types of abstractions and implementations."
      },
      {
          "question": "Which pattern is used to define a family of algorithms and encapsulate them, making them interchangeable?",
          "options": [
              "Adapter",
              "Strategy",
              "Command",
              "Observer"
          ],
          "answer": "Strategy",
          "explanation": "The Strategy pattern defines a family of algorithms, encapsulates each one, and makes them interchangeable. It allows the algorithm to vary independently from the client using it."
      },
      {
          "question": "What problem does the Proxy pattern solve?",
          "options": [
              "How to provide a simplified interface to a complex subsystem",
              "How to extend the functionality of an object dynamically",
              "How to hide the complexity of a subsystem and provide a simplified interface",
              "How to decouple an abstraction from its implementation"
          ],
          "answer": "How to hide the complexity of a subsystem and provide a simplified interface",
          "explanation": "The Proxy pattern provides a surrogate or placeholder for another object to control access to it. It is used to add an extra level of indirection and provide a simple interface to a complex subsystem."
      },
      {
          "question": "Which pattern is used to define the skeleton of an algorithm, allowing subclasses to provide specific implementation details?",
          "options": [
              "Decorator",
              "Template Method",
              "Composite",
              "Visitor"
          ],
          "answer": "Template Method",
          "explanation": "The Template Method pattern defines the skeleton of an algorithm in a base class, allowing subclasses to provide specific implementation details without changing the overall structure. It allows for code reuse and extension."
      },
      {
          "question": "What problem does the Observer pattern solve?",
          "options": [
              "How to represent a tree structure of objects",
              "How to provide a simplified interface to a complex subsystem",
              "How to add behavior to individual objects dynamically",
              "How to establish a one-to-many dependency between objects"
          ],
          "answer": "How to establish a one-to-many dependency between objects",
          "explanation": "The Observer pattern defines a one-to-many dependency between objects, where the subject (observable) notifies its dependent objects (observers) automatically of any state changes. It allows for loose coupling and keeps objects in sync."
      },
      {
          "question": "Which pattern is used to encapsulate the creation of a complex object and allow its construction step-by-step?",
          "options": [
              "Builder",
              "Prototype",
              "Abstract Factory",
              "Singleton"
          ],
          "answer": "Builder",
          "explanation": "The Builder pattern separates the construction of a complex object from its representation, allowing it to be constructed step-by-step. It provides a flexible solution for constructing complex objects."
      },
      {
          "question": "What problem does the Singleton pattern solve?",
          "options": [
              "How to define a family of algorithms and make them interchangeable",
              "How to represent a tree structure of objects",
              "How to share objects that can be used in multiple contexts efficiently",
              "How to ensure that a class has only one instance"
          ],
          "answer": "How to ensure that a class has only one instance",
          "explanation": "The Singleton pattern ensures that a class has only one instance, providing a global point of access to it. It is useful when a single object needs to coordinate actions across the system."
      },
      {
          "question": "Which pattern is used to encapsulate a request as an object, thereby allowing clients to parameterize clients with different requests?",
          "options": [
              "Visitor",
              "Chain of Responsibility",
              "Command",
              "Iterator"
          ],
          "answer": "Command",
          "explanation": "The Command pattern encapsulates a request as an object, allowing clients to parameterize clients with different requests. It allows for the separation of the object making the request from the object that performs the actual operation."
      },
      {
          "question": "What problem does the Prototype pattern solve?",
          "options": [
              "How to represent a tree structure of objects",
              "How to share objects that can be used in multiple contexts efficiently",
              "How to encapsulate a request as an object",
              "How to create new objects by cloning existing ones"
          ],
          "answer": "How to create new objects by cloning existing ones",
          "explanation": "The Prototype pattern allows for creating new objects by cloning existing ones, avoiding the overhead of creating each object from scratch. It is useful when the instantiation process is expensive or complex."
      },
      {
          "question": "Which pattern is used to define the set of objects that can be created by a factory, without specifying their concrete classes?",
          "options": [
              "Abstract Factory",
              "Builder",
              "Prototype",
              "Singleton"
          ],
          "answer": "Abstract Factory",
          "explanation": "The Abstract Factory pattern provides an interface for creating families of related or dependent objects, without specifying their concrete classes. It allows for the creation of objects with different implementations."
      },
      {
          "question": "What problem does the Chain of Responsibility pattern solve?",
          "options": [
              "How to define the set of objects created by a factory without specifying their concrete classes",
              "How to establish a one-to-many dependency between objects",
              "How to encapsulate a request as an object",
              "How to avoid coupling the sender of a request to its receiver"
          ],
          "answer": "How to avoid coupling the sender of a request to its receiver",
          "explanation": "The Chain of Responsibility pattern allows an object to send a request to a chain of potential receivers, without having to specify the receiver explicitly. It decouples the sender and receiver and allows multiple objects to handle the request."
      },
      {
          "question": "Which pattern is used to separate the construction of a complex object from its representation?",
          "options": [
              "Strategy",
              "Composite",
              "Builder",
              "Abstract Factory"
          ],
          "answer": "Builder",
          "explanation": "The Builder pattern separates the construction of a complex object from its representation, allowing for step-by-step construction. It provides control over the construction process and allows for different representations of the same object."
      },
      {
          "question": "What problem does the Visitor pattern solve?",
          "options": [
              "How to create new objects by cloning existing ones",
              "How to establish a one-to-many dependency between objects",
              "How to define the set of objects created by a factory without specifying their concrete classes",
              "How to perform an operation on a group of related objects without modifying their classes"
          ],
          "answer": "How to perform an operation on a group of related objects without modifying their classes",
          "explanation": "The Visitor pattern allows for performing an operation on a group of related objects without modifying their classes. It separates the operations from the object structure and provides a way to add new operations without modifying the objects."
      },
      {
          "question": "Which pattern is used to provide a way to access the elements of an aggregate object sequentially?",
          "options": [
              "Observer",
              "Iterator",
              "Proxy",
              "Facade"
          ],
          "answer": "Iterator",
          "explanation": "The Iterator pattern provides a way to access the elements of an aggregate object sequentially without exposing its underlying representation. It decouples the aggregate from the traversal algorithm and simplifies the interface."
      },
      {
          "question": "What problem does the Flyweight pattern solve?",
          "options": [
              "How to hide the complexity of a subsystem and provide a simplified interface",
              "How to encapsulate the creation of a complex object",
              "How to share objects that can be used in multiple contexts efficiently",
              "How to decouple an abstraction from its implementation"
          ],
          "answer": "How to share objects that can be used in multiple contexts efficiently",
          "explanation": "The Flyweight pattern is used to share objects that can be used in multiple contexts efficiently. It minimizes memory usage by reusing existing objects instead of creating new ones and allows for a large number of fine-grained objects to be created."
      },
      {
          "question": "Which pattern is used to add behavior to individual objects dynamically?",
          "options": [
              "Visitor",
              "Decorator",
              "Composite",
              "Bridge"
          ],
          "answer": "Decorator",
          "explanation": "The Decorator pattern is used to add behavior to individual objects dynamically at runtime, without affecting the behavior of other objects in the same class. It provides a flexible alternative to subclassing for extending functionality."
      },
      {
          "question": "What problem does the Abstract Factory pattern solve?",
          "options": [
              "How to separate the construction of a complex object from its representation",
              "How to add behavior to individual objects dynamically",
              "How to define the set of objects created by a factory without specifying their concrete classes",
              "How to provide a simplified interface to a complex subsystem"
          ],
          "answer": "How to define the set of objects created by a factory without specifying their concrete classes",
          "explanation": "The Abstract Factory pattern provides an interface for creating families of related or dependent objects, without specifying their concrete implementations. It allows for the creation of objects with different implementations, based on a common interface."
      }
  ]
}