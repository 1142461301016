export enum QuestionTypeConsts {
    MULTIPLE_CHOICE = "MULTIPLE_CHOICE",
    TRUE_FALSE = "TRUE_FALSE",
    FILL_IN_THE_BLANK = "FILL_IN_THE_BLANK",
    ESSAY = "ESSAY"
}

type ChoiceType = {
    answer: string,
    // code: string
}

export type QuestionType = {
    id: number,
    question: string,
    choices: ChoiceType[],
    correct: string,
    type: QuestionTypeConsts.MULTIPLE_CHOICE | QuestionTypeConsts.TRUE_FALSE | QuestionTypeConsts.FILL_IN_THE_BLANK | QuestionTypeConsts.ESSAY
    hint: string,
    explanation: string
    numberString?: string
}

// type QuizCategory = {
//     id: string,
//     title: string,
//     description: string,
// }

// type QuestionCategory = {
//     id: string,
//     title: string,
//     description: string,
// }
