import React, { useState, useEffect } from 'react';
import QuestionFactory from './question/Question';
import { QuestionType } from './question/Consts';
import Controls from './Controls'
import './style.css';
import { useMediaQuery } from 'react-responsive';
import { getQuestion } from '../../api';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';





const N_QUESTIONS = 10;
export type QuizType = {
    id: number,
    title: string,
    tags: string[],
    category: string,
    author: string,
    createdAt: string,
    questions: QuestionType[],
    published: boolean
}

type QuizRegister = {
    quiz: QuizType,
    quizAnswers: string[], // not so efficient, change to work with ids
    date: Date,
    score: number
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));



const Quiz = ({ quiz }: { quiz: QuizType }) => {
    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
    const [questionNumber, setQuestionNumber] = useState<number>(-1);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [currentQuestion, setCurrentQuestion] = useState<QuestionType | null>(null);
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [getNQuestions, setGetNQuestions] = useState<boolean>(false);
    const [questions, setQuestions] = useState<QuestionType[]>([]);
    const [open, setOpen] = React.useState(false);


    useEffect(() => {
        if (questionNumber === -1 || questionNumber === quiz.questions.length) return;

        const questionId = quiz.questions[questionNumber].id;

        async function fetchQuestion() {
            const remoteQuestion = await getQuestion({ params: { questionId } });
            setQuestions(currentQuestions => [...currentQuestions, remoteQuestion]);
            setCurrentQuestion({ ...remoteQuestion, numberString: `${questionNumber + 1}/${quiz.questions.length}` });
        }
        fetchQuestion();
    }, [questionNumber]);

    const handleClick = (value: string) => {
        const currentQuestionNumber = questionNumber;
        if (value === 'next') {
            setQuestionNumber(questionNumber + 1);
        } else if (value === 'prev') {
            setQuestionNumber(questionNumber - 1);
        } else if (value === 'submit') {
            setIsSubmitted(true);
            alert('Quiz submitted');
        } else if (value === 'retake') {
            setQuestionNumber(0);
            setIsSubmitted(false);
        }
        if ((currentQuestionNumber + 1) % N_QUESTIONS === 0 && (currentQuestionNumber + 1) > questions.length) {
            setGetNQuestions(last => !last);
        }
    }

    const handleStart = () => {

        handleClickOpen();
        setQuestionNumber(0);
        setGetNQuestions(last => !last);
    }

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = (event: any, reason: any) => {
        if (reason == 'closeClicked') {

            setOpen(false);
        }
    };

    const getPercentage = (questionNumber + 1) / quiz.questions.length * 100;

    const quizInitialView = () => {
        return (
            <div >
                <Button
                    variant="contained"
                    size="medium"
                    onClick={() => handleStart()}
                >
                    Take the Quiz
                </Button>
                {/* <h3>{quiz.title}</h3>
                <div className="quizz-surrounding" style={{ width: isMobile ? "unset" : "revert-layer" }}>
                    <div>
                        <p>Are you ready to start?</p>
                        <button onClick={() => handleStart()}>Start</button>
                    </div>
                </div> */}
            </div>
        )
    }
    // if (currentQuestion === null && !isSubmitted) {
    //     return quizInitialView();
    // }
    // else {
    return (

        <div style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <Button
                variant="contained"
                size="medium"
                onClick={() => handleStart()}
            >
                Take the Quiz
            </Button>

            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    <h3>{quiz.title}</h3>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleClose(event, 'closeClicked')}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>



                    <div className="quizz-surrounding" style={{ width: isMobile ? "unset" : "500px", minHeight: "650px", display: "flex", flexWrap: "wrap", alignContent: "space-between" }}>

                        <div >
                            {currentQuestion && (
                                <QuestionFactory question={currentQuestion} />
                            )}
                        </div>

                        <div >
                            <Controls
                                numberOfQuestions={quiz.questions.length}
                                currentQuestionNumber={questionNumber}
                                isSubmitted={isSubmitted}
                                handleClick={handleClick}

                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleClose(event, 'closeClicked')}>
                        Save changes
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    )
    // }
}

export default Quiz;