import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Post from './components/post/Post';
import Mosaic from './components/mosaic/Mosaic';
import Quiz from './components/quiz/Quiz';
import MainTheme from './components/MainTheme';
import CustomCarousel from './components/carousel/Carousel';
import Path from './components/path/Path';
import { ElementWrapper, PassthroughWrapper } from './components/router/ElementWrapper';
import { postsSample,
         quizSample,
         mosaicSample,
         pathsCardsSample,
         pathsSample

        } from './sampleData';
import {getQuizWithAdapter, getSearchResultCards, getSearchResultError} from './api';
import {getQuizzesCards, 
        getPostsCards, 
        getPost} from './api';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { Carousel } from 'react-bootstrap';
import { get } from 'http';
import { Search } from '@mui/icons-material';
import SearchBar from './components/searchBar/SearchBar';
import ErrorComponent, { ERROR_404_MESSAGE } from './common/Errors';
import ReactGA from 'react-ga';

const TRACKING_ID='G-3408X0S5S5'
const res = ReactGA.initialize(TRACKING_ID, { debug: true })


const router = createBrowserRouter(
  [
    {
      element: <MainTheme siteName='AMQuizzes' />,
      children: [
        {
          path: "/",
          element: <PassthroughWrapper />,
          loader: getPostsCards
        },
        {
          path: "/posts/:postId/:slug",
          // element: <Post />,
          element: <PassthroughWrapper />,
          loader: getPost
        },
        // {
        //   path: "/paths",
        //   element: <Mosaic 
        //               title='Paths'
        //               // cards={pathsCardsSample['cards']} 
        //               baseUrl='/paths'
        //           />,
        //   loader: getPaths
        // },
        {
          path: "/paths/:pathId",
          element: <Path paths={pathsSample} />
        }, 
        {
          path: "/quizzes/:quizId/:slug",
          element: <ElementWrapper component={Quiz} /> , 
          loader: getQuizWithAdapter  
            
        },
        {
          path: "/quizzes",
          element:  <PassthroughWrapper/>,
          loader: getQuizzesCards
            
        },
        {
          path: "/search/:search_text",
          element:  <PassthroughWrapper />,
          loader: getSearchResultCards
        },
        {
          path: "/search/",
          element:  <PassthroughWrapper />,
          loader: getSearchResultError
        },
        // every other path
        {
          path: "*",
          element: <ErrorComponent errorMessage={ERROR_404_MESSAGE} />,
        }

      ]
    }
  ]
);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);



root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
