import React from "react";
import { SelectChangeEvent } from "@mui/material/Select";
import OutlinedCard from './Card';
import { OutlinedCardProps } from "./Card";
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Select, MenuItem, Card } from "@mui/material";
import TablePagination from '@mui/material/TablePagination';
import { useLoaderData } from "react-router-dom";


export type MosaicProps = {
    title?: string;
    cards: OutlinedCardProps[];
    baseUrl: string;
}
export type TileProps = {
    cards: OutlinedCardProps[];
    baseUrl?: string;
    itemsPerPage: number;
    currentPage: number;
}


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const Tile = (props: TileProps) => {
    // const startItem = props.itemsPerPage * (props.currentPage - 1);
    // const endItem = (props.itemsPerPage * props.currentPage + 1) - 1
    const startItem = props.itemsPerPage * (props.currentPage);
    const endItem = (props.itemsPerPage * (props.currentPage + 1)) 
    return (

        // <Box sx={{ width: '100%' }}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} alignItems="stretch">
                {props.cards.slice(startItem, endItem).map((card, index) => (
                    <Grid key={index} item xs={12} sm={6} md={4} lg={4} xl={3} style={{display:'flex'}} >
                        <OutlinedCard
                            key={index}
                            {...card}
                            // targetId={card.targetId}
                            // title={card.title}
                            // category={card.category}
                            // summary={card.summary}
                            // updatedAt={card.updatedAt}
                            // author={card.author}
                            // buttonText={card.buttonText}
                            buttonBaseUrl={props.baseUrl? props.baseUrl : card.buttonBaseUrl}
                        />
                    </Grid>
                ))}

            </Grid>
        // </Box>
    );
}

const Mosaic = (props: MosaicProps) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    // const cards = useLoaderData() as OutlinedCardProps[];

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    return (
        <div>
            <br />
            {props.title && <><h2> {props.title}</h2></>}
            {/* if not props.title then br */}
            <br />
            <Tile {...props} cards={props.cards} currentPage={page} itemsPerPage={rowsPerPage} />
            <TablePagination
                component="div"
                count={props.cards.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="Items per page"
            />
        </div>
    );
}

export default Mosaic;