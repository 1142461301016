import React, { useEffect, useState } from "react";
import BadgeIcon from '@mui/icons-material/Badge';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import Container from '@mui/material/Container';
import { ThemeProvider, createTheme } from '@mui/system';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ReactGA from 'react-ga';


import { useLoaderData, useParams } from "react-router-dom";
import Quiz from "../quiz/Quiz";
import { getPost, getQuiz } from "../../api";
var parse = require('html-react-parser');

export type PostProps = {
    id: number;
    title: string;
    author: string;
    published: string;
    category: string;
    content: any;
    createdAt: string;
    quiz: any;
}
export type PostsProps = {
    posts: PostProps[];
}

const Post = (props: any) => {
    const [quiz, setQuiz] = useState(null);
    const [post, setPost] = useState(null);


    useEffect(() => {
        if (!post) return;
        async function fetchQuiz() {
            const quizData = await getQuiz({ params: { quizId: post.props.quiz } });
            console.log('quizData', quizData);
            setQuiz(quizData);
        }
        fetchQuiz();
    }, [post]);

    useEffect(() => {
        // ReactGA.ga('send', 'pageview', window.location.pathname);
        // ReactGA.pageview(window.location.pathname)
        ReactGA.pageview(window.location.pathname + window.location.search);
        async function fetchPost() {
            const postData = await getPost({ params: { postId: props.id } });
            console.log('postData', postData);
            setPost(postData);
        }
        fetchPost();
    }, []);

    const getQuizProps = (quiz: any) => {
        const result = {
            id: quiz.id,
            title: quiz.title,
            tags: quiz.tags,
            category: quiz.category,
            author: quiz.author,
            createdAt: new Date(quiz.createdAt.slice(0, 10)).toDateString(),
            questions: quiz.questions,
            published: quiz.published
        }
        return result;
    }

    const getTagList = (tags: any) => {
        const tagList = tags.map((element: any) => {
            return element.name
        });
        return tagList.toString("23432423")
    }

    return post && (
        <>
            {post && <div style={{ width: "100%" }}>
                <h1>{post.props.title}</h1>
                <pre >
                    {post.props.author} <BadgeIcon fontSize="small" style={{ verticalAlign: "sub" }} /> |
                    {post.props.createdAt} <CalendarMonthIcon fontSize="small" style={{ verticalAlign: "sub" }} />
                </pre>
                <pre >

                    {post.props.length != 0  &&  getTagList(post.props.tags.slice(0, 5))  } {post.props.tags.length != 0 && <LocalOfferIcon fontSize="small" style={{ verticalAlign: "sub" }} /> }
                </pre>
                    {quiz && <Quiz quiz={getQuizProps(quiz)} />}

                {parse(post.props.content)}

            </div>
            }
        </>
    );
}
export default Post;

