import React, { useEffect, useState } from "react";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Transition } from 'react-transition-group';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';


import { QuestionTypeConsts, QuestionType } from "./Consts";
// import styles from style.css
import './style.css';
import { VisibilitySharp } from "@mui/icons-material";
import { TIMEOUT } from "dns";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
}));

// Create another component with MultipleChoiceQuestionType as a prop
const MultipleChoiceQuestion = ({ question }: { question: QuestionType }) => {
    const [selected, setSelected] = useState<string>("");
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [showExplanation, setShowExplanation] = useState<boolean>(false);

    useEffect(() => {
        setShowExplanation(false);
        setIsOpen(false);
        const timeout = setTimeout(() => {
            setShowExplanation(true);
        }, 50);
        return () => clearTimeout(timeout);
    }, [question.question]);

    function getStyle(text: string, correct: string) {
        const localStyle = { listStyleType: "none" }
        if (selected !== text) {
            return { ...localStyle, backgroundColor: "" }
        }
        if (selected === text && text === correct) {
            return { ...localStyle, backgroundColor: "Green" }
        } else {
            return { ...localStyle, backgroundColor: "Red" }
        }
    }
    const getPercentage = (numberString: string) => {
        const questionNumber = parseInt(numberString.split("/")[0])
        const totalQuestions = parseInt(numberString.split("/")[1])

        return questionNumber / totalQuestions * 100
    }

    return (
        <div>

            <h4>
                {question.numberString && question.numberString}
                <Box sx={{ position: 'relative', width: "100%" }}>
                    <BorderLinearProgress variant="determinate" value={getPercentage(question.numberString)} />
                </Box>
                <br />
                {question.hint &&
                    <Tooltip title={question.hint}>
                        <HelpIcon />
                    </Tooltip>
                }

                {question.question}
            </h4>
            <ul className="list-group">
                {
                    question.choices.map((choice, index) => {

                        return (
                            <li className={`list-group-item
                                ${question.correct === choice.answer && 'list-group-item-success'}
                                ${question.correct !== choice.answer && 'list-group-item-danger'}`}
                                style={{ listStyleType: "none" }}
                            >
                                <label><input type="radio" name={question.id.toString()} onClick={() => setSelected(choice.answer)} />
                                    <i className="fas fa-check float-right" style={getStyle(choice.answer, question.correct)}>
                                        {choice.answer}
                                    </i>
                                </label>
                            </li>
                        )
                    })

                }
            </ul>
            <br />
            {question.explanation &&
                <Accordion
                    expanded={isOpen}
                    onClick={() => setIsOpen(past => !past)}
                >
                    {/*TODO  Show explanation only when the user selected one choice */}
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Explanation</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {showExplanation && question.explanation
                            }
                        </Typography>
                    </AccordionDetails>
                </Accordion>

            }
        </div>
    )

}

export default MultipleChoiceQuestion;