
var BASE_URL_CONST =""
if (process.env.REACT_APP_LOCAL_ENV === "development") {
    BASE_URL_CONST ="https://bm6upjxv39.execute-api.us-east-1.amazonaws.com/dev/api/v1"
}

export const BASE_URL = BASE_URL_CONST
export const SEARCH_URL =  "/text/?search="
export const QUIZZES_URL = "/quizzes"
export const QUESTIONS_URL = "/questions"
export const POST_URLS = "/articles"