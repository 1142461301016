// Create a factory for questions types

import React from 'react';

import MultipleChoiceQuestion from './MultipleChoiceQuestion';
// import TrueFalseQuestion from './question/TrueFalseQuestion';
// import FillInTheBlankQuestion from './question/FillInTheBlankQuestion';
// import EssayQuestion from './question/EssayQuestion';
// import Question from './question/Question';
// import {QuestionTypeConsts} from './Consts';
import {QuestionType} from './Consts';

const QuestionFactory = ({question}: {question:QuestionType}) => {
    
        switch (question.type||"MULTIPLE_CHOICE") {
            case "MULTIPLE_CHOICE":
                return <MultipleChoiceQuestion question={question} />
            // case "TRUE_FALSE":
            //     return <TrueFalseQuestion question={question} />
            // case "FILL_IN_THE_BLANK":
            //     return <FillInTheBlankQuestion question={question} />
            // case "ESSAY":
            //     return <EssayQuestion question={question} />
            default:
                return <div></div>
        }
    
    }

export default QuestionFactory;