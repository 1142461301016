import * as React from 'react';
// import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
var parse = require('html-react-parser');
var slugify = require('slugify');

const CARD_N_WORDS = 20;
// const bull = (
//     <Box
//         component="span"
//         sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
//     >
//         •
//     </Box>
// );

const CustomCard = (props: OutlinedCardProps) => (
    <React.Fragment>
        <CardContent>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                {props.category}
            </Typography>
            <Typography variant="h6" component="div">
                {props.title}
            </Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                {props.updatedAt}
            </Typography>
            <Typography variant="body2">
                {parse(props.summary?.split(' ').slice(0,CARD_N_WORDS).join(' '))}
                
            </Typography>
        </CardContent>
        <CardActions>
            <Button size="small" href={props.buttonBaseUrl + "/"+props.targetId.toString() + "/" + slugify(props.title, {lower: true}) }>{props.buttonText} </Button>
        </CardActions>
    </React.Fragment>
);

export type OutlinedCardProps = {
    targetId: number
    title: string
    category: string
    summary: string
    updatedAt: string
    author: string
    buttonText: string
    buttonBaseUrl?: string
    slug: string
    listIcon?: any
}
const OutlinedCard = (props: OutlinedCardProps) => {
    return (
        // <Box sx={{ minWidth: 210 }} >
        // <Box sx={{ minWidth: '200px', width:'100%' }} >
            // <Card variant="outlined" style={{display: 'flex', justifyContent: 'space-between', alignItems:'space-between', width:'100%', flexDirection: 'column'}}>
            // <Card variant="outlined" style={{display: 'flex', justifyContent: 'space-between', alignItems:'space-between', width:'100%', flexDirection: 'column'}}>
            <Card variant="outlined" style={{display: 'flex', justifyContent: 'space-between', alignItems:'space-between', width:'100%', flexDirection: 'column'}}>
                <CustomCard {...props} />
            </Card>
        // </Box>
    );
}

export default OutlinedCard;