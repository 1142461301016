import * as React from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Outlet } from 'react-router-dom';
import Link from '@mui/material/Link';
import { NavLink } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import RouteIcon from '@mui/icons-material/Route';
import { Grid } from '@mui/material';
import SearchBar from './searchBar/SearchBar';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import ArticleIcon from '@mui/icons-material/Article';
import QuizIcon from '@mui/icons-material/Quiz';
import { useMediaQuery } from 'react-responsive';
import { ThemeProvider, createTheme } from '@mui/system';
import { useEffect } from 'react';



// const drawerWidth = "fit-content" //240;
const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

type MainThemeProps = {
  content?: any
  siteName?: string
}


const MainTheme = (props: MainThemeProps) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  // const [offset, setOffset] = React.useState("0px");
//   const refDrawer = React.useRef(null);


//   useEffect(() => {
//     if (refDrawer.current && open) {
//         setOffset(refDrawer.current.offsetWidth);
//     }
// }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const DrawerComponent = () => {

    return <Drawer variant="permanent" open={open} >
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        {[{ text: 'Home', url: '/', icon: <HomeIcon /> },
        // { text: 'Paths', url: '/paths', icon: <RouteIcon /> },
        { text: 'Quizzes', url: '/quizzes', icon: <QuizIcon /> }
        ].map((item, index) => (
          <NavLink to={item.url} key={item.text} style={{ textDecoration: 'none', color: 'inherit' }}>
            <ListItem key={item.text} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.text} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          </NavLink>
        ))}
      </List>
      {/* <Divider />
    <List>
      {['All mail', 'Trash', 'Spam'].map((text, index) => (
        <ListItem key={text} disablePadding sx={{ display: 'block' }}>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : 'auto',
                justifyContent: 'center',
              }}
            >
              {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
            </ListItemIcon>
            <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
      ))}
    </List> */}
    </Drawer>
  }
  
  useEffect(() => {
    console.log('isMobile', isMobile)
  }, []);
  return (
    <>
      {/* <Box sx={{ display: 'flex' }}> */}
        <CssBaseline />
        {/* <AppBar position="fixed" open={open} style={{left:`${offset}px`}}> */}
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div" style={{width:'100%'}}>
              <Stack direction="row" spacing={12} sx={{width:'100%',display:'flex', flexDirection:'row', justifyContent:'space-between', flexWrap:'wrap', alignItems:'center'}} >
                <Link  href='/' underline="none" color="white">{props.siteName}</Link>
                <SearchBar  />
                {/* <TextField id="outlined-search" sx={{width:"20%"}}label="Search field" type="search" size="small" /> */}

              </Stack>


            </Typography>
          </Toolbar>
        </AppBar>
        
        {!isMobile  && DrawerComponent() }
        {isMobile  && open && DrawerComponent() }

        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          {/* {props.content} */}
          <ThemeProvider
        theme={createTheme({
          breakpoints: {
            values: {
                xs: 400,
                sm: 640,
              md: 1024,
              lg: 1280,
              xl: 1600,
            },
          },
        })}
      >
  
      </ThemeProvider> 
          <Grid container spacing={2}>
            <Grid item md={1} lg={2} />
            <Grid item xs={12} sm={12} md={10} lg={8}>
              <Outlet />
            </Grid>
            <Grid item md={1} lg={2} />
          </Grid>
        </Box>
      {/* </Box> */}
    </>
  );
}

export default MainTheme;