import React from "react";
import { useLoaderData } from "react-router-dom";


type WrapperType = {
    component: JSX.ElementType;
}    
export const ElementWrapper = (props: WrapperType) => {
    const newProps = useLoaderData() as any;
    let Component = props.component;
    return (<Component {...newProps} />);

}

export const PassthroughWrapper = () => {
    const Component = useLoaderData() as any;
    return Component;

}