import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';



// import ExampleCarouselImage from 'components/ExampleCarouselImage';

function CustomCarousel() {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex: number) => {
        setIndex(selectedIndex);
    };

    return (
        <Carousel activeIndex={index} onSelect={handleSelect}>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src="/img/banner.png"
                    alt="Image One"
                    max-height="300px"
                    max-width="300px"
                />
                {/* <ExampleCarouselImage text="First slide" /> */}
                <Carousel.Caption>
                    {/* <h3>First slide label</h3> */}
                    {/* <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    );
}

export default CustomCarousel;