
export const ERROR_404_MESSAGE = "404 - Page not found";

type errorComponentProps = {
    errorMessage: string
}

const ErrorComponent = (props:errorComponentProps)=> {
    return (
        <div>
        <h1>{props.errorMessage}</h1>
        </div>
    );
    }

export default ErrorComponent;